<template>
  <Fragment>
    <span
      v-for="idx in 3"
      :key="idx"
      :class="[classes, idx === index ? 'warning--text' : 'secondary--text']"
      @click="onChange(idx)"
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="15"
          cy="15"
          r="14.5"
          stroke="currentColor"
        />

        <circle
          cx="10.3334"
          cy="11.8889"
          r="1.55556"
          fill="currentColor"
          stroke="currentColor"
        />

        <circle
          cx="19.6669"
          cy="11.8889"
          r="1.55556"
          fill="currentColor"
          stroke="currentColor"
        />

        <path
          v-if="idx === 1"
          d="M9.28413 22.3999C9.90103 21.8657 10.7586 21.445 11.7503 21.1588C12.7459 20.8714 13.866 20.7224 15.0001 20.7224C16.1341 20.7224 17.2543 20.8714 18.2498 21.1588C19.2415 21.445 20.0991 21.8657 20.716 22.3999"
          fill="currentColor"
        />

        <path
          v-else-if="idx === 2"
          d="M9.49458 20.0448C10.1115 20.579 10.9691 20.9997 11.9607 21.2859C12.9563 21.5733 14.0764 21.7223 15.2105 21.7223C16.3446 21.7223 17.4647 21.5733 18.4603 21.2859C19.4519 20.9997 20.3095 20.579 20.9264 20.0448"
          fill="currentColor"
        />

        <path
          v-else-if="idx === 3"
          d="M9.42903 19.6667C9.15086 19.6667 9.01178 19.6667 8.93678 19.7668C8.86178 19.8668 8.89812 19.9913 8.9708 20.2403C9.65764 22.5935 12.0965 24.3334 14.9999 24.3334C17.9032 24.3334 20.3421 22.5935 21.0289 20.2403C21.1016 19.9913 21.138 19.8668 21.063 19.7668C20.988 19.6667 20.8489 19.6667 20.5707 19.6667H9.42903Z"
          fill="currentColor"
        />
      </svg>
    </span>
  </Fragment>
</template>
<script>
import { Fragment } from 'vue-fragment'
export default {
  name: 'UGradingSmiles',

  components: { Fragment },

  props: {
    value: {
      type: Number,
      default: 0,
    },

    readonly: {
      type: Boolean,
      default: true,
    }
  },

  data () {
    return {
      index: 0,
    }
  },

  computed: {
    classes () {
      return {
        smile: true,
        readonly: this.readonly,
      }
    }
  },

  created () {
    this.index = this.value ? Math.round(this.value / 50) + 1 : this.value
  },

  methods: {
    onChange (value) {
      if (!this.readonly) {
        this.index = value
        this.$emit('input', value - 1)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.smile {
  padding: 0.5rem;
  cursor: pointer;
}

.smile.readonly {
  cursor: auto;
}
</style>
