<template>
  <div class="task__answers">
    <template v-if="isRate">
      <p>{{ $t('$app.choose_answer_on_scale') }}:</p>
      <VSlider
        :value="value / 10"
        color="primary"
        :min="0"
        :max="10"
        :step="1"
        :tick-labels="ticksLabels"
        track-color="#D9D9D9"
        :readonly="isReadonly"
        @input="onUpdate($event * 10)"
      />
    </template>

    <template v-else-if="isStars">
      <p>{{ $t('$app.your_mark') }}:</p>
      <VRating
        background-color="warning lighten-1"
        color="warning"
        :value="value / 20"
        :readonly="isReadonly"
        hover
        @input="onUpdate($event * 20)"
      />
    </template>

    <template v-else-if="isLikes">
      <p>{{ $t('$app.choose_one_answer.one') }}:</p>
      <UGradingSmiles
        :readonly="isReadonly"
        :value="value"
        @input="onUpdate(Math.floor($event * 50) || 1)"
      />
    </template>
  </div>
</template>

<script>
import UGradingSmiles from '@components/UGradingSmiles.vue'

export default {
  name: 'TrainingQuizQuestionTypeGrading',

  components: { UGradingSmiles },

  props: {
    value: {
      type: Number,
      default: 0,
    },

    type: {
      type: String,
      default: 'rate',
    },

    isReadonly: {
      type: Boolean,
      default: true,
    }
  },

  data () {
    return {
      ticksLabels: ['0', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    }
  },

  computed: {
    isRate () {
      return this.type === 'rate'
    },

    isStars () {
      return this.type === 'stars'
    },

    isLikes () {
      return this.type === 'likes'
    }
  },

  methods: {
    onUpdate (value) {
      this.$emit('input', [value])
    }
  }
}
</script>
